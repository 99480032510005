<template>
  <page-section
    v-loading="form.isLoading || isLoading"
    :page-title="isNewGroup ? $t('pages.relations.groups.group_create.create_your_group') : $t('pages.relations.groups.group_create.update_your_group')"
    :page-tooltip="$t('pages.relations.groups.group_create.sidebar_description')"
    class="ManageConnectGroups"
  >
    <form @submit.prevent="submit">
      <div class="columns is-aligned-bottom">
        <div class="column is-8">
          <form-field
            :label="$t('general.name')"
            :validator="$v.form.name"
          >
            <input
              v-model="form.name"
              :placeholder="$t('components.relation_group_create.name_of_group')"
              class="input"
              name="name"
              type="text"
              data-testid="GroupInputName"
            >
          </form-field>
        </div>

        <div class="column is-4 has-text-right">
          <v-button
            type="button"
            class="is-text"
            data-testid="CancelUpdateFormButton"
            @click="cancel"
          >
            {{ $t('forms.cancel') }}
          </v-button>

          <v-button
            :loading="form.isLoading"
            type="submit"
            class="is-primary"
            :data-testid="isNewGroup ? 'CreateGroupFormButton' : 'UpdateGroupFormButton'"
          >
            {{ isNewGroup ? $t('forms.create') : $t('forms.save') }}
          </v-button>
        </div>
      </div>
    </form>
  </page-section>
</template>

<script>
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'

import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

export default {
  props: {
    groupId: {
      type: [String, Number],
      default: null
    }
  },

  data: () => ({
    isLoading: false,
    form: new Form({
      name: { value: '', rules: { required } }
    }),
    selectedRelations: [],
    searchQuery: ''
  }),

  computed: {
    isNewGroup () {
      return !this.groupId
    },

    relationsEndpoint () {
      return buildUrlWithParams('connect/relations', {
        brand: this.activeBrandId,
        search: this.searchQuery
      })
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  mounted () {
    this.fetchGroup()
  },

  async beforeRouteUpdate (to, from, next) {
    await this.fetchGroup()
    next()
  },

  beforeDestroy () {
    this.reset(true)
  },
  methods: {
    fetchGroup () {
      if (this.isNewGroup) return

      this.isLoading = true

      const payload = {}

      return this.$api.get(`/connect/groups/${this.groupId}`, payload)
        .then(response => {
          this.isLoading = false

          this.form.merge({ ...response.data.data })
        })
        .catch(error => {
          this.isLoading = false

          this.$displayRequestError(error, this.$t('errors.error'))
        })
    },

    // async syncSearchResults () {
    //   try {
    //     await this.$confirm(this.$t('pages.relations.groups.group_manage.confirm'))

    //     const payload = { query: this.searchQuery, brand: this.activeBrandId }

    //     await this.$api.post(`/connect/groups/${this.groupId}/relations/add-batch`, payload)

    //     await this.updateGroup()
    //   } catch (err) {
    //     if (err === 'cancel') return
    //     this.$displayRequestError(err, this.$t('errors.error'))
    //   }
    // },

    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) return

      if (this.isNewGroup) {
        await this.createGroup()
      } else {
        await this.updateGroup()
      }
    },

    async createGroup () {
      await this.form.post(`/connect/groups?brand=${this.activeBrandId}`).then(response => {
        let groupId = response.data.id

        this.$notify({
          title: this.$t('general.success'),
          message: this.$t('success.relation_group_added'),
          type: 'success'
        })

        this.$router.push({ name: 'connect.groups.view', params: { groupId: groupId } })
      }).catch(err => {
        this.$displayRequestError(err)
      })
    },

    async updateGroup () {
      await this.form.patch(`/connect/groups/${this.groupId}`).then(response => {
        // await this.syncRelationsOnGroup(this.groupId, this.selectedRelations)

        this.$notify({
          title: this.$t('general.success'),
          message: this.$t('success.relation_group_updated'),
          type: 'success'
        })

        this.$router.push({ name: 'connect.groups.view', params: { groupId: this.groupId } })
      }).catch(err => {
        this.$displayRequestError(err)
      })
    },

    async reset (hardReset) {
      // order is important here, first reset the form, then the validation
      hardReset ? this.form.reset() : this.form.revertToRevision()

      await this.$nextTick()

      this.$v.form.$reset()
    },

    async cancel () {
      try {
        await this.$confirm(this.$t('warnings.cancel_group_management'))

        if (this.isNewGroup) {
          this.$router.push({ name: 'connect.groups' })
        } else {
          this.$router.push({ name: 'connect.groups.view', params: { groupId: this.groupId } })
        }
      } catch (err) {}
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.relationGroupRelationsLoadMore .Collection__load-more {
  margin-top: $margin !important;
}
</style>
